<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>
<div *ngIf="!runData" class="headerRule">Manage allocation rules</div>
<div *ngIf="!runData" id="mainContent" class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <div class="searchContainer">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          [(ngModel)]="searchText"
          style="width: 100%; margin-right: 24px; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"
        ></ap-search>
        <ap-button
          [icon]="'plus-outline'"
          [compact]="true"
          [add]="true"
          (onClick)="openAddAllocationRule()"
          kendoTooltip
          title="New"
        ></ap-button>
      </div>

      <div class="subHeader">Manage allocation rules</div>
      <kendo-grid
        [data]="allocationRules"
        scrollable="virtual"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        class="gridHeight"
        [reorderable]="true"
      >
        <kendo-grid-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'left'
          }"
          [width]="40"
        >
          <ng-template
            kendoGridHeaderTemplate
            let-column
            let-columnIndex="columnIndex"
          >
            <input
              type="checkbox"
              (change)="selectAllrules($event)"
              kendoCheckBox
            />
          </ng-template>
          <ng-template kendoGridCellTemplate let-data>
            <input
              type="checkbox"
              (change)="onHighlightClick(data)"
              [checked]="checkIfchecked(data)"
              kendoCheckBox
            />
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-command-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'left'
          }"
          field="IsActive"
          title="Actions"
          [width]="160"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              kendoButton
              kendoTooltip
              title="Edit"
              name="edit"
              (click)="editButtonClick(data)"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-edit-outline"></span>
            </button>
            <button
              kendoButton
              kendoTooltip
              title="Copy"
              name="copy"
              (click)="copyButtonClick(data)"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-copy-outline"></span>
            </button>
            <button
              kendoButton
              kendoTooltip
              title="Delete"
              name="delete"
              (click)="deleteButtonClick(data)"
            >
              <span class="Appkit4-icon icon-delete-outline"></span>
            </button>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column
          field="Priority"
          title="Priority"
          [width]="120"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Name"
          title="Name"
          [width]="320"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="TargetAccount"
          title="Target account"
          [width]="220"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Habitat"
          title="Habitat"
          [width]="250"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'left'
          }"
          field="Priority Action"
          title="Priority action"
          [width]="160"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              kendoButton
              name="up"
              (click)="moveupPriority(data)"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-arrow-up-small-outline"></span>
            </button>
            &nbsp;
            <button
              kendoButton
              name="down"
              (click)="movedownPriority(data)"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-arrow-down-small-outline"></span>
            </button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <div class="clickToActions" style="padding-top: 10px">
        <ap-button
          [btnType]="'primary'"
          [label]="'Define expressions'"
          (click)="clickDefineExpressions()"
          style="margin-left: 7px"
        ></ap-button>
        <ap-button
          [btnType]="'secondary'"
          [label]="'Run'"
          (click)="run()"
          [disabled]="clickedItems.length < 1"
          style="margin-left: 7px"
        ></ap-button>
        <!-- <ap-button [btnType]="'secondary'" [label]="'Report'" style="margin-left: 7px;"></ap-button> -->
        <ap-button
          [btnType]="'secondary'"
          (onClick)="exportReport()"
          [label]="'Export'"
        ></ap-button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="runData">
  <div class="headerRule">Confirm auto allocations</div>
  <div class="container-fluid">
    <div class="row">
      <div col-md-12 col-xs-12 style="margin-bottom: 10px">
        <small style="justify-content: center; padding-left: 10px"
          >The system has run the allocation rules listed below and identified
          the following transactions for auto allocation <br />
          <small style="justify-content: center; padding-left: 10px"></small>
          Review the transactions in the list and confirm those for which you
          want to create journals
        </small>
      </div>
    </div>
  </div>

  <div
    style="overflow-x: scroll; overflow-y: scroll; height: calc(100vh - 250px)"
  >
    <table
      id="customers"
      style="
        width: 96%;
        margin-left: auto;
        margin-right: auto;
        /* margin-right: 54px; */
      "
    >
      <th style="width: 20px"></th>
      <th style="font-weight: bold; color: #d04a02; text-align: center">
        Description
      </th>
      <tbody *ngFor="let a of allocationRules1">
        <td style="width: 30px">
          <input
            style="margin: 0px 11px"
            type="checkbox"
            [(ngModel)]="a.tablecheckbox"
            (change)="onHighlightClick1(a, $event)"
            kendoCheckBox
            [disabled]="a.HasExportedOfflineReferral"
          />
        </td>
        <td>
          <span style="display: flex">
            <div
              style="
                font-size: 14px;
                cursor: pointer;
                font-family: 'PwC Helvetica Neue';
                margin-top: -2px;
              "
              (click)="expand(a)"
            >
              {{ a.Ruleheading }}{{ roundedPercentage(a.headingAmount) }}
            </div>
          </span>
          <div></div>
          <div
            style="overflow-x: hidden"
            *ngIf="a.autoAllocationTransactionPreview.length > 0"
          >
            <table id="customers" *ngIf="isSet(a.Id)">
              <thead>
                <tr>
                  <th></th>
                  <th
                    style="
                      font-weight: bold;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    Description
                  </th>
                  <th
                    style="
                      font-weight: bold;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    Amount
                  </th>
                  <th
                    style="
                      font-weight: bold;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    Document type
                  </th>
                  <th
                    style="
                      font-weight: bold;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    Document date
                  </th>
                  <th
                    style="
                      font-weight: bold;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    Tax code
                  </th>
                  <th
                    style="
                      font-weight: bold;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    Cost center
                  </th>
                  <th
                    style="
                      font-weight: bold;
                      width: 30px;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    Vendor
                  </th>
                  <th
                    style="
                      font-weight: bold;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    Transaction type
                  </th>
                  <th
                    style="
                      font-weight: bold;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    Period
                  </th>
                  <th
                    style="
                      font-weight: bold;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    GL account code
                  </th>
                  <th
                    style="
                      font-weight: bold;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    Transaction date
                  </th>
                  <th
                    style="
                      font-weight: bold;
                      width: 50px;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    Legal entity
                  </th>
                  <th
                    style="
                      font-weight: bold;
                      color: #d04a02;
                      font-size: 13px;
                      white-space: nowrap;
                    "
                  >
                    Fiscal year
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let b of a.autoAllocationTransactionPreview"
                  style="font-size: 10px; white-space: nowrap"
                  [ngStyle]="{
                    'background-color':
                      b.IsDuplicate || b.HasExportedOfflineReferral
                        ? '#FFDCA9'
                        : ''
                  }"
                >
                  <td>
                    <input
                      style="margin-left: 11px; margin-right: 22px"
                      type="checkbox"
                      [checked]="isCheckedC(b, a)"
                      (change)="onHighlightClickC(b, a)"
                      [disabled]="b.HasExportedOfflineReferral"
                      kendoCheckBox
                    />
                  </td>
                  <td>{{ b.Description }}</td>
                  <td>
                    {{ roundedPercentage(b.Amount) }}
                  </td>
                  <td>{{ b.DocumentType }}</td>
                  <td>{{ b.DocumentDate }}</td>
                  <td>{{ b.TaxCode }}</td>
                  <td>{{ b.CostCentre }}</td>
                  <td>{{ b.Vendor }}</td>
                  <td>{{ b.TransactionType }}</td>
                  <td>{{ b.Period }}</td>
                  <td>{{ b.GLAccountCode }}</td>
                  <td>{{ b.TransactionDate }}</td>
                  <td>{{ b.LegalEntity }}</td>
                  <td>{{ b.FiscalYear }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tbody>
    </table>
  </div>
  <!-- <kendo-grid [data]="allocationRules1" scrollable="virtual" 
    [height]="300"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)" > 
    <kendo-grid-column   [headerStyle] ="{'font-weight' : 'bold', 'color': '#d04a02', 'text-align': 'left'}"  [width]="40">
      <ng-template kendoGridCellTemplate let-data>
        <input type="checkbox" (change)=onHighlightClick(data) [checked]="isChecked(data)" kendoCheckBox />
       </ng-template>
    </kendo-grid-column>   
    <kendo-grid-column field="Ruleheading" title="Description" [width]="320" [headerStyle] ="{'font-weight' : 'bold', 'text-align': 'left', 'color': '#d04a02'}">
    
    </kendo-grid-column>
    </kendo-grid>
  -->
  <div class="clickToActions" style="padding-top: 15px">
    <ap-button
      [btnType]="'secondary'"
      (click)="closePop()"
      style="margin-left: 12px; margin-right: 7px"
      [label]="'Close'"
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      (click)="createJournals()"
      [disabled]="getStatus()"
      style="margin-left: 12px; width: 165px"
      [label]="'Create journals'"
    ></ap-button>

    <ap-button
      [btnType]="'primary'"
      [label]="'Export'"
      [disabled]="getStatus()"
      (click)="export()"
      style="margin-left: 7px; width: 100px"
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      [label]="'Select unallocated'"
      (click)="selectUnallocated()"
      style="margin-left: 7px"
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      (click)="selectall()"
      [label]="'Select all'"
    ></ap-button>
  </div>
</div>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{ display: displayStyle }"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Auto allocation</h5>
      </div>
      <div class="modal-body">
        <p>{{ resData }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger1" (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
