<kendo-dialog-titlebar (close)="closeButtonClick()">
  <div style="font-size: 18px; line-height: 1.1em;

font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) 24px/24px var(--unnamed-font-family-helvetica-neue-lt-pro);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--default-color);
text-align: left;
font: normal normal medium 24px/24px Helvetica Neue LT Pro;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
  
  ">Legend</div>
</kendo-dialog-titlebar>
<div *ngIf="isLoading">
  <kendo-chunkprogressbar [chunkCount]="10" [min]="-10" [max]="10" [indeterminate]="true" [value]="30"
    style="width: 100%;"></kendo-chunkprogressbar>
</div>

<div class="row">
  <div class="col">
    <kendo-icon class="Appkit4-icon icon-network-fill" style="cursor: pointer; color: #A7452C;font-size: 17px;">
    </kendo-icon>
  </div>
  <div class="col">
    <kendo-icon class="Appkit4-icon icon-data-fill" style="cursor: pointer; color: #C34C2F;
font-size: 18px;
    
    ">
    </kendo-icon>
  </div>
  <div class="col">
    <kendo-icon class="Appkit4-icon icon-library-reports-fill" style="cursor: pointer; color: #299D8F;font-size: 18px;">
    </kendo-icon>
  </div>
  <div class="col">
    <kendo-icon class="Appkit4-icon icon-folder-opened-fill" style="cursor: pointer; color: #d93954;font-size: 18px;">
    </kendo-icon>
  </div>

  <div class="col">
    <kendo-icon class="Appkit4-icon icon-gantt-chart-fill" style="cursor: pointer; color: #e0301e ;font-size: 18px;">
    </kendo-icon>
  </div>
  <div class="col">
    <kendo-icon class="Appkit4-icon icon-notebook-fill" style="cursor: pointer; color: #464646 ;font-size: 18px;">
    </kendo-icon>
  </div>

  <div class="col">
    <kendo-icon class="Appkit4-icon icon-paper-outline" style="cursor: pointer; color: #7d7d7d ;font-size: 18px;">
    </kendo-icon>

  </div>
</div>



<div class="row">
  <div class="col" style="
 
  color: #000000;
  opacity: 1;">


    Business type
  </div>
  <div class="col" style="color:#000000">

    Legal entity
  </div>
  <div class="col" style="color:#000000">

    Analysis pack
  </div>
  <div class="col" style="color:#000000">

    Analysis statement
  </div>

  <div class="col" style="color:#000000">

    Sub analysis
  </div>
  <div class="col" style="color:#000000">

    Complex line item
  </div>

  <div class="col" style="color:#000000">

    Line
    item
  </div>
</div>

<!-- <kendo-dialog-actions>
  <div class="clickToActions" style="padding: 2px 0px;">
  </div>
</kendo-dialog-actions> -->