// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiPointer: 'server',
  // baseUrl:'https://wezgrlttadwa014.azurewebsites.net',
  importapiUrl: 'https://wezehahzapap004.azurewebsites.net',
  baseUrl: 'https://enlightenapp.pwc.co.uk',

  basePort: '',
  apiPort: ':8443',
  apifolder: '/api',
  authComponent: 'openam',
  openam: {
    authorization_endpoint: 'https://login.pwc.com/openam/oauth2/authorize',
    token_endpoint: 'https://login.pwc.com/openam/oauth2/access_token',
    user_profile_url: 'https://login.pwc.com/openam/oauth2/userinfo',
    //app_url: 'https://enlighten.pwc.co.uk',
    app_url: 'https://transactiondataassist.pwc.com',
    //app_url: 'http://localhost:4000',
    client_id: 'urn:prodtransactiondataassist',
    logoutUrl: 'https://login.pwc.com:443/openam/UI/Logout',
    token_info:
      'https://login.pwc.com/openam/oauth2/tokeninfo?access_token=',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
