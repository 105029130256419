
         
          <!-- <kendo-grid-spacer></kendo-grid-spacer>
          <div class="container2" style="bottom:0px;position:fixed">
            <kendo-icon  class="Appkit4-icon icon-management-outline" style="cursor:pointer;color: #d04a02;;" ></kendo-icon>&nbsp;&nbsp;Business Type
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <kendo-icon  class="Appkit4-icon icon-governance-outline" style="cursor:pointer;color: #d04a02;;" ></kendo-icon>&nbsp;&nbsp;Legal Entity
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <kendo-icon  class="Appkit4-icon icon-platform-outline" style="cursor:pointer;color: #d04a02;;" ></kendo-icon>&nbsp;&nbsp;Analysis pack
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <kendo-icon  class="Appkit4-icon icon-notebook-outline" style="cursor:pointer;color: #d04a02;;" ></kendo-icon>&nbsp;&nbsp;Analysis Statement
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <kendo-icon  class="Appkit4-icon icon-revenue-outline" style="cursor:pointer;color: #d04a02;;" ></kendo-icon>&nbsp;&nbsp;sub Analysis
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
             <kendo-icon  class="Appkit4-icon icon-document-text-outline" style="cursor:pointer;color: #d04a02;;" ></kendo-icon>&nbsp;&nbsp;Complex Line Item
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <kendo-icon  class="Appkit4-icon icon-document-text-outline" style="cursor:pointer;color: #d04a02;;" ></kendo-icon>&nbsp;&nbsp;Line Item
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            
          </div> -->

          <kendo-splitter>
            <kendo-splitter-pane style="width: 50%">
              <div id="mainContent" class="container-fluid" style="overflow: auto">
                <!-- <div *ngFor="let data of data">
                          {{data.name}}
                          </div> -->
          
                <div #target class="target">
                  <kendo-chunkprogressbar
                    *ngIf="isLoading"
                    [chunkCount]="10"
                    [min]="-10"
                    [max]="10"
                    [indeterminate]="true"
                    [value]="30"
                    style="width: 100%"
                  ></kendo-chunkprogressbar>
                  <kendo-treelist
                    [data]="rootData"
                    [fetchChildren]="fetchChildren"
                    [hasChildren]="hasChildren"
                    [selectable]="true"
                    (selectionChange)="rowSelect($event)"
                    kendoTreeListExpandable
                    kendoTreeListSelectable
                    [resizable]="IsResizable"
                    (expand)="fitColumns(); onExpand($event)"
                    (collapse)="fitColumns(); onCollapse($event)"
                    (dataStateChange)="fitColumns()"
                    [isExpanded]="isExpanded"
                    [rowClass]="rowCallback"
                    class="autoTreeHeight"
                  >
                    
                    <kendo-treelist-column
                      [expandable]="true"
                      [width]="500"
                      [style]="{ 'font-size': '13px', 'height': '47px' }"
                    >
                      <ng-template kendoTreeListCellTemplate let-dataItem>
                        <kendo-icon
                          class="Appkit4-icon icon-network-fill"
                          *ngIf="dataItem.type === 'BT'"
                          style="cursor: pointer; color: #a7452c"
                        ></kendo-icon>
                        <kendo-icon
                          class="Appkit4-icon icon-data-fill"
                          style="cursor: pointer; color: #c34c2f"
                          *ngIf="dataItem.type === 'LE'"
                        >
                        </kendo-icon>
                        <kendo-icon
                          class="Appkit4-icon icon-library-reports-fill"
                          style="cursor: pointer; color: #299D8F"
                          *ngIf="dataItem.type === 'AP'"
                        ></kendo-icon>
                        <kendo-icon
                          class="Appkit4-icon icon-folder-opened-fill"
                          style="cursor: pointer; color: #d93954"
                          *ngIf="dataItem.type === 'AS'"
                        ></kendo-icon>
                        <kendo-icon
                          class="Appkit4-icon icon-gantt-chart-fill"
                          style="cursor: pointer; color: #e0301e"
                          *ngIf="dataItem.type === 'SA'"
                        ></kendo-icon>
                        <kendo-icon
                          class="Appkit4-icon icon-notebook-fill"
                          style="cursor: pointer; color: #e0301e"
                          *ngIf="dataItem.type === 'SS'"
                        ></kendo-icon>
                        <kendo-icon
                          class="Appkit4-icon icon-paper-outline"
                          style="cursor: pointer; color: #7d7d7d"
                          *ngIf="dataItem.type === 'LI'"
                        >
                        </kendo-icon>
                        <kendo-icon
                          class="Appkit4-icon icon-notebook-fill"
                          style="cursor: pointer; color: #464646"
                          *ngIf="dataItem.type === 'CLI'"
                        ></kendo-icon>
                        {{ dataItem.name }}
                        <kendo-menu
                          #target
                          [openOnClick]="true"
                          *ngIf="dataItem.type === 'LE'"
                        >
                          <kendo-menu-item icon="menu" [cssClass]="'menu-right'">
                            <ng-container *ngFor="let item of LEOPtions">
                              <kendo-menu-item [cssClass]="item.cssClass">
                                <ng-template kendoMenuItemTemplate>
                                  <span (click)="onSelect(item, dataItem)">{{
                                    item.text
                                  }}</span>
                                </ng-template>
                              </kendo-menu-item>
                            </ng-container>
                          </kendo-menu-item>
                        </kendo-menu>
                        <kendo-menu [openOnClick]="true" *ngIf="dataItem.type === 'AP'">
                          <kendo-menu-item icon="menu" [cssClass]="'menu-right'">
                            <ng-container *ngFor="let item of APoptions">
                              <kendo-menu-item [cssClass]="item.cssClass">
                                <ng-template kendoMenuItemTemplate>
                                  <span (click)="onSelect(item, dataItem)">{{
                                    item.text
                                  }}</span>
                                </ng-template>
                              </kendo-menu-item>
                            </ng-container>
                          </kendo-menu-item>
                        </kendo-menu>
                        <kendo-menu [openOnClick]="true" *ngIf="dataItem.type === 'SS'">
                          <kendo-menu-item icon="menu" [cssClass]="'menu-right'">
                            <ng-container *ngFor="let item of SSoptions">
                              <kendo-menu-item [cssClass]="item.cssClass">
                                <ng-template kendoMenuItemTemplate>
                                  <span (click)="onSelect(item, dataItem)">{{
                                    item.text
                                  }}</span>
                                </ng-template>
                              </kendo-menu-item>
                            </ng-container>
                          </kendo-menu-item>
                        </kendo-menu>
                        <kendo-menu
                          [openOnClick]="true"
                          *ngIf="
                            dataItem.type === 'AS' &&
                            dataItem.parentStatementAnalysis != true &&
                            dataItem.isSummary != true &&
                            dataItem.name != 'Unused Line Item '
                          "
                        >
                          <kendo-menu-item icon="menu" [cssClass]="'menu-right'">
                            <ng-container *ngFor="let item of ASoptions">
                              <kendo-menu-item [cssClass]="item.cssClass">
                                <ng-template kendoMenuItemTemplate>
                                  <span (click)="onSelect(item, dataItem)">{{
                                    item.text
                                  }}</span>
                                </ng-template>
                              </kendo-menu-item>
                            </ng-container>
                          </kendo-menu-item>
                        </kendo-menu>
                        <kendo-menu
                          [openOnClick]="true"
                          *ngIf="
                            dataItem.type === 'AS' && dataItem.name === 'Unused Line Item'
                          "
                        >
                        </kendo-menu>
                        <kendo-menu
                          [openOnClick]="true"
                          *ngIf="
                            dataItem.type === 'SA' &&
                            dataItem.summarySubAnalysis != true &&
                            dataItem.isSummary != true
                          "
                        >
                          <kendo-menu-item icon="menu" [cssClass]="'menu-right'">
                            <ng-container *ngFor="let item of SAoptions">
                              <kendo-menu-item [cssClass]="item.cssClass">
                                <ng-template kendoMenuItemTemplate>
                                  <span (click)="onSelect(item, dataItem)">{{
                                    item.text
                                  }}</span>
                                </ng-template>
                              </kendo-menu-item>
                            </ng-container>
                          </kendo-menu-item>
                        </kendo-menu>
                        <kendo-menu
                          [openOnClick]="true"
                          *ngIf="
                            (dataItem.type === 'LI' || dataItem.type === 'CLI') &&
                            dataItem.isSummary != true &&
                            dataItem.summaryLineItem != true &&
                            dataItem.Satementid != 0
                          "
                        >
                          <kendo-menu-item icon="menu">
                            <ng-container *ngFor="let item of CLIoptions">
                              <kendo-menu-item>
                                <ng-template kendoMenuItemTemplate>
                                  <span (click)="onSelect(item, dataItem)">{{
                                    item.text
                                  }}</span>
                                </ng-template>
                              </kendo-menu-item>
                            </ng-container>
                          </kendo-menu-item>
                        </kendo-menu>
                        <kendo-menu
                          [openOnClick]="true"
                          *ngIf="
                            (dataItem.type === 'LI' || dataItem.type === 'CLI') &&
                            dataItem.isSummary != true &&
                            dataItem.Satementid == 0
                          "
                        >
                          <kendo-menu-item icon="menu">
                            <ng-container *ngFor="let item of ULI_Childoptions">
                              <kendo-menu-item>
                                <ng-template kendoMenuItemTemplate>
                                  <span (click)="onSelect(item, dataItem)">{{
                                    item.text
                                  }}</span>
                                </ng-template>
                              </kendo-menu-item>
                            </ng-container>
                          </kendo-menu-item>
                        </kendo-menu>
                        <kendo-menu
                          [openOnClick]="true"
                          *ngIf="
                            dataItem.type === 'SA' &&
                            dataItem.summarySubAnalysis === true &&
                            dataItem.isSummary != true
                          "
                        >
                          <kendo-menu-item icon="menu">
                            <ng-container *ngFor="let item of SS_SAoptions">
                              <kendo-menu-item>
                                <ng-template kendoMenuItemTemplate>
                                  <span (click)="onSelect(item, dataItem)">{{
                                    item.text
                                  }}</span>
                                </ng-template>
                              </kendo-menu-item>
                            </ng-container>
                          </kendo-menu-item>
                        </kendo-menu>
                        <kendo-menu
                          [openOnClick]="true"
                          *ngIf="
                            dataItem.type === 'AS' &&
                            dataItem.parentStatementAnalysis === true &&
                            dataItem.isSummary != true
                          "
                        >
                          <kendo-menu-item icon="menu">
                            <ng-container *ngFor="let item of SS_ASoptions">
                              <kendo-menu-item>
                                <ng-template kendoMenuItemTemplate>
                                  <span (click)="onSelect(item, dataItem)">{{
                                    item.text
                                  }}</span>
                                </ng-template>
                              </kendo-menu-item>
                            </ng-container>
                          </kendo-menu-item>
                        </kendo-menu>
                        <kendo-menu
                          [openOnClick]="true"
                          *ngIf="
                            (dataItem.type === 'LI' || dataItem.type === 'CLI') &&
                            dataItem.isSummary === true
                          "
                        >
                          <kendo-menu-item icon="menu">
                            <ng-container *ngFor="let item of SS_LIOptions">
                              <kendo-menu-item>
                                <ng-template kendoMenuItemTemplate>
                                  <span (click)="onSelect(item, dataItem)">{{
                                    item.text
                                  }}</span>
                                </ng-template>
                              </kendo-menu-item>
                            </ng-container>
                          </kendo-menu-item>
                        </kendo-menu>
                        <!-- <kendo-menu  [openOnClick]="true" *ngIf="(dataItem.type==='LI' || dataItem.type==='CLI') && dataItem.isSummary===true">
                                                  <kendo-menu-item icon="menu" >
                                                    <ng-container *ngFor="let item of SS_LEoptions">
                                                      <kendo-menu-item >
                                                        <ng-template  kendoMenuItemTemplate>
                                                          <span (click)="onSelect(item,dataItem)">{{item.text}}</span>
                                                        </ng-template>
                                                      </kendo-menu-item>
                                                    </ng-container>
                                                  </kendo-menu-item>
                                                  </kendo-menu> -->
                        <!-- <kendo-menu  [openOnClick]="true" *ngIf="dataItem.type==='SA' && dataItem.isSummary===true">
                                                    <kendo-menu-item icon="menu" >
                                                      <ng-container *ngFor="let item of SS_Childoptions">
                                                        <kendo-menu-item >
                                                          <ng-template  kendoMenuItemTemplate>
                                                            <span (click)="onSelect(item,dataItem)">{{item.text}}</span>
                                                          </ng-template>
                                                        </kendo-menu-item>
                                                      </ng-container>
                                                    </kendo-menu-item>
                                                    </kendo-menu> -->
                        <!-- <kendo-menu  [openOnClick]="true" *ngIf="dataItem.type==='AS' && dataItem.isSummary===true">
                                                      <kendo-menu-item icon="menu" >
                                                        <ng-container *ngFor="let item of SS_Childoptions">
                                                          <kendo-menu-item >
                                                            <ng-template  kendoMenuItemTemplate>
                                                              <span (click)="onSelect(item,dataItem)">{{item.text}}</span>
                                                            </ng-template>
                                                          </kendo-menu-item>
                                                        </ng-container>
                                                      </kendo-menu-item>
                                                      </kendo-menu> -->
                      </ng-template>
                    </kendo-treelist-column>
                    <!-- <kendo-treelist-column-group title="RollingAmountData"  [style]="{'overflow':'scroll'}"> -->
                    <kendo-treelist-column [width]="500" [style]="{'text-align': 'right','height': '47px'}">
                      <ng-template kendoTreeListCellTemplate let-dataItem>
                        <!-- {{dataItem.RollingAmount[0].year}} -->
                        <!--  -->
                        <div *ngFor="let i of dataItem.RollingAmountData; let k = index">
                          <table class="table">
                            <tr *ngIf="dataItem.firstItem === true">
                              <th>{{ i.year }}</th>
                            </tr>
                            <tr *ngIf="i.RollingAmount > 0 || i.RollingAmount == 0">
                              <td style="text-align: right; padding: 0px">
                                <!-- {{ i.RollingAmount.toLocaleString("en-IN") }} -->
                                {{
                                  roundedPercentage(
                                    i.RollingAmount,
                                    dataItem.RollingAmountData[0].RollingAmount,
                                    k
                                  )
                                }}
                              </td>
                            </tr>
                            <tr *ngIf="i.RollingAmount < 0">
                              <td
                                [style]="
                                  reportType == 'Percentage' && k != 0
                                    ? 'text-align: right'
                                    : 'color: red; text-align: right; padding: 0px'
                                "
                              >
                                {{
                                  roundedPercentage(
                                    i.RollingAmount,
                                    dataItem.RollingAmountData[0].RollingAmount,
                                    k
                                  )
                                }}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </ng-template>
                    </kendo-treelist-column>
                    <!-- </kendo-treelist-column-group> -->
                  </kendo-treelist>
                </div>
              </div>
            </kendo-splitter-pane>
          
            <kendo-splitter-pane *ngIf="displayRight" style="width: 50%">
              <ap-tabset [type]="'filled'" [(activeIndex)]="activeIndex">
                <ap-tab
                  *ngFor="let tab of tabs; let i = index"
                  [label]="tab.label"
                  [icon]="tab.icon"
                  [disabled]="tab.disabled"
                >
                  <!-- custom content here -->
                  <!-- <kendo-grid [data]='analysisStatementData' scrollable="none">                
                      </kendo-grid> -->
          
                  <app-show-gl-accounts
                    *ngIf="tab.label === 'GLAccounts' && activeIndex === 1"
                    [selectedRow]="selectedRow"
                  ></app-show-gl-accounts>
          
                  <app-analysis-statement
                    *ngIf="tab.label === 'Analysis statement' && activeIndex === 0"
                    [selectedRow]="selectedRow"
                  ></app-analysis-statement>
                  <app-show-transactions
                    *ngIf="tab.label === 'Transactions' && activeIndex === 2"
                    [selectedRow]="selectedRow"
                  ></app-show-transactions>
          
                  <!--<div class="buttonalignright">
                    <button kendoButton (click)="openWindow()">
                      <span
                        class="Appkit4-icon icon-open-in-new-window-outline"
                      ></span></button
                    >&nbsp;
                    <button kendoButton>
                      <span class="Appkit4-icon icon-search-outline"></span>
                    </button>
                    &nbsp;
                    <button kendoButton>
                      <span class="Appkit4-icon icon-upload-light-outline"></span>
                    </button>
                    &nbsp;
                  </div> -->
                </ap-tab>
              </ap-tabset>
            </kendo-splitter-pane>
          </kendo-splitter>